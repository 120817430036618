import React from "react"
import Page from "@components/page"

const NotFoundPage = () => {
  return (
    <Page title="" description="">
      Not Found
    </Page>
  )
}

export default NotFoundPage
